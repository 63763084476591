import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useMediaQuery } from 'usehooks-ts'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { SplitText } from 'gsap/SplitText'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Seo from '../components/seo'
import Layout from '../components/layout'

import MainCTA from '../components/sections/main-cta'

const MissionPage = () => {
  const isMobile = useMediaQuery('(max-width: 767px)')

  const data = useStaticQuery(graphql`
    query {
      allWpTimelineYear(sort: { order: DESC, fields: title }) {
        nodes {
          title
          timelineFields {
            shortYear
            months {
              month
              text
              title
            }
          }
        }
      }
      allWp {
        nodes {
          missionPageOptions {
            missionPageOptions {
              title
              timelineHeader {
                title
                text
                subtitle
              }
              video {
                url
                text
              }
            }
          }
        }
      }
    }
  `)

  const options = data.allWp.nodes[0].missionPageOptions.missionPageOptions

  const numYears = data.allWpTimelineYear.nodes.length
  console.log(numYears)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText)

    /* make arrays of key elements */
    const sections = gsap.utils.toArray('.timeline-section')
    const years = gsap.utils.toArray('.year')
    const links = gsap.utils.toArray('.year-link')

    /* Calculate positions */
    function getNavHeight() {
      return document.querySelector('#navBar').offsetHeight
    }

    const mm = gsap.matchMedia()

    mm.add('(max-width: 767px)', () => {
      /* Make active year active, change nav-link to active */
      sections.forEach((section, i) => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: section,
            start: 'top 60%',
            end: 'bottom 60%',
            toggleClass: { targets: links[i], className: 'active' },
            scrub: false,
          },
        })
      })
    })

    mm.add('(min-width: 768px)', () => {
      /* Define the pinned item */
      const pinMe = document.getElementById('pinMe')
      function getPinBottom() {
        const navHeight = document.querySelector('#navBar').offsetHeight
        const pinHeight = pinMe.offsetHeight
        const pinOffsetFromSection = sections[0].children[0].offsetTop
        return `${navHeight + pinHeight + pinOffsetFromSection}px`
      }
      /** Pin the 20 * */
      ScrollTrigger.create({
        trigger: '.timeline-section',
        start: `top top+=${getNavHeight()}px`,
        endTrigger: years[years.length - 1],
        end: `bottom ${getPinBottom()}`,
        pin: '#pinMe',
        pinSpacing: false,
      })
      /* Change year color as section scrolls */
      sections.forEach((section, i) => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: section,
            start: `top ${getPinBottom()}`,
            endTrigger: years[i],
            end: `bottom ${getPinBottom()}`,
            scrub: true,
          },
        })
        const nextSectionColor = gsap.getProperty(years[i], 'color')
        if (i !== 0) {
          tl.to(pinMe, { color: nextSectionColor })
        }
      })
      /* Make active year active, change nav-link to active */
      sections.forEach((section, i) => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: section,
            start: `top ${getPinBottom()}`,
            end: `bottom ${getPinBottom()}`,
            toggleClass: { targets: links[i], className: 'active' },
            scrub: false,
          },
        })
      })
    })

    /** Header text */
    const mySplitText = new SplitText('#missionHeader h1', { type: 'lines, words' })
    const { lines } = mySplitText

    gsap.from(lines, {
      duration: 1,
      opacity: 0,
      // scale: 0.8,
      y: 100,
      ease: 'back.out(1.2)',
      stagger: 0.3,
      // color: "#FFFFFF"
    })
    /** Animate Roots  * */
    const rootsTl = gsap.timeline({
      scrollTrigger: {
        trigger: '#timeline',
        start: 'top 50%',
        end: 'bottom bottom',
        scrub: 2.5,
      },
    })
    rootsTl.to('.timeline-roots', { maskSize: '100% 100%', ease: 'none' })

    /* Click year links to scroll to section */
    links.forEach((link, i) => {
      link.addEventListener('click', () => {
        gsap.to(window, { duration: 1, scrollTo: { y: sections[i], offsetY: getNavHeight } })
      })
    })
    /** Show/hide the timeline navigation * */
    ScrollTrigger.create({
      trigger: '#timeline',
      start: 'top 25%',
      end: 'bottom 75%',
      pinSpacing: false,
      toggleClass: { targets: '#timelineNav', className: 'active' },
    })

    ScrollTrigger.refresh()

    gsap.to(window, { duration: 0.1, scrollTo: 0 })

    return () => {
      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll()
      if (triggers) {
        triggers.forEach(trigger => {
          trigger.kill()
        })
      }
    }
  }, []) // end useffect

  return (
    <Layout>
      <Seo title="Mission" />
      <div id="missionTop">
        <header className="pb-3 pt-2 py-sm-4 py-lg-5 pt-xl-7 pb-xl-8 bg-primary" id="missionHeader">
          <h1 className="text-warning fw-bold text-center mb-0 px-1 px-xl-3">{options.title}</h1>
        </header>
        <section id="missionImage" className="stack-parent">
          <div className="stack-child">
            <div className="video-container">
              <iframe
                src={`${options.video.url}&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1&background=1&controls=0"`}
                allow="autoplay;"
                title="Meati™ mission video"
              />
            </div>
          </div>
          <div id="missionText" className="py-4 px-2 py-sm-5 px-sm-4 py-md-7 stack-child d-flex align-items-center">
            <div className="container-narrower">
              <p className="mb-0 fw-bold h3 text-center text-white lh-base">{options.video.text}</p>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-dark text-white pt-2 pb-2" style={{ fontSize: '1.25rem' }}>
        <div className="container-narrower">
          <h2 className="fs-1 mb-2">Nothing Fake About Real Food</h2>
          <p className="subtitle h4 mt-3">
            Ultra-processed? Nah. That’s not our style.
            <br /> We think it’s time for something better.
            <br /> Something real.
          </p>
          <p className="subtitle h5 mt-3">Meet Mycelium. The Real Deal. </p>
          <p>
            Meati is all about real food, made from real ingredients, with real benefits. Our mycelium-based protein is
            packed with everything you want—complete protein, fiber, and essential nutrients—without the stuff you
            don’t. No binders, fillers, or unnecessary processing. Just simple, whole-food goodness that tastes amazing
            and fuels your body. 
          </p>
          <p>And the best part? It’s better for the planet too. </p>
          <ul>
            <li>
              Sustainability with purpose: Meati’s mycelium uses 73% less water and produces 89% fewer greenhouse gas
              emissions compared to traditional animal beef. 
            </li>
            <li>
              Scaling nutrition, not resources: We can create the equivalent protein of 200 cows in one week—without the
              environmental cost. 
            </li>
            <li>
              Less land, more possibility: Our mycelium requires 10x less land than soy, making it a smarter, more
              efficient way to feed the world. 
            </li>
          </ul>
          <p className="subtitle h5 mt-3">No Compromises. Just Better Food. </p>
          <p>
            Great food shouldn’t make you choose between taste, nutrition, and sustainability—it should give you all
            three. That’s what we’re here to do. 
          </p>
          <p>
            Mycelium is naturally functional, meaning it cooks, chews, and satisfies like the real thing without needing
            a chemistry experiment to make it happen. Plus, it’s naturally a powerhouse of protein and fiber, supporting
            gut health while delivering that hearty bite you crave. 
          </p>
          <p className="subtitle h5 mt-3">Join the Real Food Movement </p>
          <p>
            This isn’t just about Meati—it’s about rethinking the way we eat. It’s about raising the bar and choosing
            food that actually makes a difference. Because when you start with something real, you don’t need to fake
            it. 
          </p>
          <p>Let’s eat better. Let’s demand more. Let’s make real food the new standard. </p>
          <p>Let’s change the protein paradigm. Are you in? </p>
        </div>
      </div>
      <div id="timeline">
        <div className="bg-warning pt-4 pb-3 ps-5 ps-sm-5 ps-md-6 ps-xl-0" id="timelineIntro">
          <div className="container">
            <h2 className="fs-1 mb-2">{options.timelineHeader.title}</h2>
            <p className="subtitle h4 mb-2">{options.timelineHeader.subtitle}</p>
            <div dangerouslySetInnerHTML={{ __html: options.timelineHeader.text }} />
          </div>
        </div>
        {data.allWpTimelineYear.nodes.map((node, index) => (
          <section
            key={`year-${index}`}
            className="py-4 ps-5 ps-sm-5 ps-md-6 ps-xl-0 timeline-section position-relative"
          >
            <Container>
              <Row>
                <Col md="4" xl="5">
                  <Row className="g-0 mb-2 mb-xl-0">
                    <Col md="6" className="d-none d-md-flex justify-content-end px-0">
                      {index === 0 && (
                        <h2 className="mb-0 fw-bold" id="pinMe">
                          20
                        </h2>
                      )}
                    </Col>
                    <Col md="6" className="px-0">
                      <h2 className="mb-0 year fw-bold">{isMobile ? node.title : node.timelineFields.shortYear}</h2>
                    </Col>
                  </Row>
                </Col>
                <Col md="8" xl="7" className="month-column">
                  {node.timelineFields.months.map((node, index) => (
                    <div className="timeline-month" key={`month-${index}`}>
                      <h3 className="fs-1">{node.month}</h3>
                      <p className="fst-italic h4">{node.title}</p>
                      <div dangerouslySetInnerHTML={{ __html: node.text }} />
                    </div>
                  ))}
                </Col>
              </Row>
            </Container>
          </section>
        ))}
        <div className="timeline-roots" />
        <div id="timelineNav" className="top-50 start-0 translate-middle-y position-fixed py-1">
          <nav className="nav flex-column">
            {data.allWpTimelineYear.nodes.map((node, i) => (
              <div role="button" className="py-12 ps-12 pe-1 px-md-1 pe-lg-2 fw-bold year-link" key={`year-link-${i}`}>
                {node.title}
                {i + 1 === numYears && <span>& beyond</span>}
              </div>
            ))}
          </nav>
        </div>
      </div>
      <MainCTA />
    </Layout>
  )
}

export default MissionPage
